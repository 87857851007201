.founder-section {
  margin-top: 80px;
  background: #e5f4f1;

  &__image {
    max-width: 800px;
    width: 100%;
  }
  &__title-mobile {
    display: none;
  }
  &__image-mobile {
    display: none;
  }

  &__content {
    padding: 60px;

    &-title {
      font-family: $font-family1;
      font-weight: 700;
      font-size: 64px;
      line-height: 64px;
      color: #1c1d1e;
    }
    &-description {
      font-family: $font-family2;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.09em;
      color: #24252f;
    }
  }
}

@media (max-width: 768px) {
  .founder-section {
    margin-top: 0px;
    padding: 0 24px;
    .image-wrapper {
      position: relative;
      top: -250px;
      margin-bottom: -250px;
    }
    &__title-mobile {
      display: flex;
      font-family: $font-family1;
      font-weight: 700;
      font-size: 40px;
      line-height: 100%;
      color: #1c1d1e;
    }
    &__image {
      display: none;
    }
    &__image-mobile {
      display: flex;
      max-width: 380px;
      width: 100%;
    }

    &__content {
      padding: 60px;
      &-title {
        display: none;
      }
    }
  }
}
