$font-family1: 'Raleway', sans-serif;
$font-family2: 'Cabin', sans-serif;

$button-color: #1e1e1e;

@mixin button-styles {
  color: #fff;
  background-color: $button-color;
  padding: 16px 32px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  font-family: $font-family1;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.full-container {
  max-width: 1410px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
}
