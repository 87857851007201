.navbar {
  padding: 15px 39px !important;
  background-color: #e5f4f1;
  &-wrapper {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-button {
      @include button-styles;
    }
  }
}

@media (max-width: '768px') {
  .navbar {
    padding: 15px !important;
    &-wrapper {
      &-logo {
        max-width: 162px;
        width: 100%;
      }
    }
  }
}
