.home-section {
  background: #e5f4f1;
  padding-bottom: 100px;
  padding-top: 50px;
  position: relative;
  z-index: -1;

  &__content {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    font-family: $font-family1;
    font-weight: 700;
    font-size: 80px;
    line-height: 80px;
    color: #1e1e1e;

    span {
      color: transparent;
      background-image: linear-gradient(
        240deg,
        rgba(204, 21, 112, 1),
        rgba(255, 112, 51, 1),
        rgba(254, 188, 39, 1),
        rgba(0, 182, 224, 1)
      );
      -webkit-background-clip: text;
      background-clip: text;
    }
  }

  &__description {
    margin: 0;
    font-family: $font-family2;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.09em;
    color: #24252f;
    margin: 24px 0;
  }

  &__logos {
    display: flex;
    gap: 25px;
    align-items: center;
    margin-top: 78px;
  }

  &__image-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__image {
    max-width: 660px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .home-section {
    padding-top: 40px;
    padding-bottom: 0px;

    &__title {
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
    }

    &__description {
      margin-bottom: 0;
    }

    .home-logos {
      display: none;
    }
  }
}
