.cards-section {
  position: relative;
  &__title {
    font-family: $font-family1;
    font-weight: 700;
    font-size: 64px;
    line-height: 64px;
    color: #1c1d1e;
  }

  &__description {
    font-family: $font-family2;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.09em;
    color: #24252f;
  }

  &__button {
    @include button-styles;
  }

  &__wrapper::after {
    content: '';
    position: absolute;
    z-index: -10;
    top: 120px;
    width: 10000px;
    height: 500px;
    background: url(../images/cards-section-background.png);
    background-repeat: no-repeat;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 40px;
    overflow-x: hidden;
  }

  &__card {
    box-shadow: 0px 64px 72px -48px rgba(0, 0, 0, 0.16),
      0px 48px 56px -72px rgba(0, 0, 0, 0.16);
    border-radius: 40px;
    background-color: #fff;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  &__card-content {
    padding: 24px;
  }

  &__card-image {
    width: 100%;
    height: 250px;
  }

  &__card-title {
    font-family: $font-family1;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: #1c1d1e;
  }

  &__card-button {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__card-button-link {
    font-family: $font-family1;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #cc1570;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $button-color;
    }
  }
}

@media (max-width: 768px) {
  .cards-section {
    overflow-x: hidden;
    padding-bottom: 280px;
    &__title {
      font-size: 40px;
      line-height: 40px;
    }

    &__wrapper::after {
      content: '';
      top: 12.5%;
      left: -100px;
      width: 1000px;
      height: 1700px;
      background-repeat: no-repeat;
      background-size: cover;
      overflow-x: hidden;
    }

    &__button {
      margin-bottom: 40px;
    }

    &__card {
      margin-bottom: 24px;
    }

    &__card-button {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}

@media (max-width: 541px) {
  .cards-section {
    &__wrapper::after {
      top: 11.5%;
      width: 1000px;
      height: 1700px;
    }
  }
}

@media (max-width: 415px) {
  .cards-section {
    &__wrapper::after {
      top: 12.2%;
      width: 1000px;
      height: 1700px;
    }
  }
}

@media (max-width: 376px) {
  .cards-section {
    &__wrapper::after {
      top: 13.7%;
      width: 1000px;
      height: 1700px;
    }
  }
}
