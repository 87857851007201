.our-process {
  position: relative;
  z-index: 1;
  top: -100px;
  .our-process__wrapper {
    background: #fff;
    border-radius: 80px;
    padding: 100px 0;
    margin-bottom: 80px;
    box-shadow: 0px 40px 64px -24px rgba(0, 0, 0, 0.24);

    .our-process-logos {
      display: none;
    }
    .our-process__column {
      &--left {
        max-width: 450px;

        .our-process__column-title {
          margin: 0;
          font-family: $font-family1;
          font-weight: 700;
          font-size: 64px;
          line-height: 64px;
          color: #1e1e1e;
          margin-bottom: 45px;
        }
      }
      &--right {
        max-width: 552px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .our-process__column-title {
          font-family: $font-family1;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          color: #1e1e1e;
          border-left: 5px solid #00b6e0;
          padding-left: 24px;
        }
      }
    }

    .our-process__item {
      display: flex;
      align-items: center;
      gap: 22px;
      margin-bottom: 16px;

      &-text {
        margin: 0;
        font-family: $font-family2;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.06em;
        color: #57595b;
      }
    }

    .our-process__description {
      font-family: $font-family2;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.06em;
      color: #57595b;
    }
  }
}

@media (max-width: 768px) {
  .our-process {
    top: -30px;
    .our-process__wrapper {
      background: none;
      border-radius: 24.3061px;
      padding: 0 18px;
      padding-top: 0px;
      margin-bottom: 40px;
      box-shadow: none;
      .our-process-logos {
        display: flex;
        margin-bottom: 55px;
        .home-section__logos {
          img {
            max-width: 50px !important;
          }
        }
      }

      .our-process__column {
        &--left {
          .our-process__column-title {
            margin: 0;
            font-weight: 700;
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 23px;
          }
        }
      }
    }
  }
}
