.contact-modal {
  top: 0;
  position: fixed;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;

  &__wrapper {
    max-width: 600px;
    width: 100%;
    background-color: #fff;
    padding: 40px;
    border-radius: 24px;
    position: relative;

    &-x {
      padding: 5px 10px;
      border: 3px solid black;
      width: fit-content;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: -10px;
      right: -10px;
    }

    &-title {
      font-family: $font-family1;
      font-weight: 700;
      font-size: 64px;
      line-height: 100%;
      text-align: center;
      color: #1e1e1e;
    }
    &-description {
      font-family: $font-family1;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      text-align: center;
      color: #1e1e1e;
    }

    &-form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &-input {
        border: 3px solid #bebebe;
        border-radius: 100px;
        padding: 12px 14px;
        font-family: $font-family1;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #1c1d1e;

        &::placeholder {
          color: #1c1d1e;
        }
      }

      &-button {
        @include button-styles;
      }
    }
  }
}

@media (max-width: 768px) {
  .contact-modal {
    top: 0;
    position: fixed;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;

    &__wrapper {
      max-width: 600px;
      width: 100%;
      background-color: #fff;
      padding: 40px;
      border-radius: 24px;
      position: relative;

      &-x {
        padding: 5px 10px;
        border: 3px solid black;
        width: fit-content;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: -10px;
        right: -10px;
      }

      &-title {
        font-family: $font-family1;
        font-weight: 700;
        font-size: 34px;
        line-height: 50%;
        text-align: center;
        color: #1e1e1e;
      }
      &-description {
        font-family: $font-family1;
        font-weight: 500;
        font-size: 18px;
        line-height: 80%;
        text-align: center;
        color: #1e1e1e;
      }

      &-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &-input {
          border: 3px solid #bebebe;
          border-radius: 100px;
          padding: 12px 14px;
          font-family: $font-family1;
          font-weight: 800;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          color: #1c1d1e;

          &::placeholder {
            color: #1c1d1e;
          }
        }

        &-button {
          @include button-styles;
        }
      }
    }
  }
}

@media (max-height: 720px) {
  .contact-modal {
    &__wrapper {
      height: 500px;
      overflow-y: scroll;
      overflow-x: visible;

      &-x {
        top: 10px;
        right: 10px;
      }
    }
  }
}

@media (max-height: 550px) {
  .contact-modal {
    &__wrapper {
      height: 400px;
      overflow-y: scroll;
      overflow-x: visible;

      &-x {
        top: 10px;
        right: 10px;
      }
    }
  }
}

@media (max-height: 420px) {
  .contact-modal {
    &__wrapper {
      height: 300px;
      overflow-y: scroll;
      overflow-x: visible;

      &-x {
        top: 10px;
        right: 10px;
      }
    }
  }
}
